import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "src/app/providers/login.service";
import { ToasterService } from "src/app/providers/toaster.service";

import {
  AuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angular5-social-login";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  public isMenuCollapsed = true;
  private user: SocialUser;
  public authorized: boolean = false;
  private readonly email_pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  show_button: Boolean = false;
  show_eye: Boolean = false;
  showLoader: boolean;
  isSocialUser: boolean = false;
  socialUserData: {
    email: string;
    name: string;
    id: string;
    idToken: string;
    provider: string;
    image: string;
    token: string;
  };
  
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private toasterService: ToasterService,
    private socialAuthService: AuthService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          // Validators.pattern(this.email_pattern)
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          // Validators.minLength(8),
          // Validators.pattern(
          //   "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          // ),
        ],
      ],
    });
  }
  get f() {
    return this.loginForm.controls;
  }

  enter(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.loginForm.value);

    if (this.loginForm.invalid) {
      return;
    } else {
      this.showLoader = true;
      this.loginService.getlogin(this.loginForm.value).subscribe(
        (res: any) => {
          // console.log('response', res)
          const message = res.message;
          if (res.status == "success") {
            // console.log('role', res.data.user_data['role'])
            localStorage.setItem("userRole", res.data.user_data["role"]);
            localStorage.setItem("launch_apps", res.data.user_data["launch_apps"]);
            localStorage.setItem("create_event", res.data.user_data["create_event"]);
            localStorage.setItem("view_public_event", res.data.user_data["view_public_event"]);
            localStorage.setItem("userEmail", res.data.user_data["email"]);
            localStorage.setItem("userCompany", res.data.user_data["company"]);
            localStorage.setItem("userName", res.data.user_data["user_name"]);
            localStorage.setItem("privatetoken", res.data.user_data["privatetoken"]);
            localStorage.setItem("publictoken", res.data.user_data["publictoken"]);
            localStorage.setItem("accountName", res.data.user_data["storageaccount"]);
            localStorage.setItem("PrivateContainerName", res.data.user_data["privatecontainer"]);
            localStorage.setItem("PublicContainerName", res.data.user_data["publiccontainer"]);
            this.toasterService.showSuccess("Login Success", "");
            this.authorized == false;
            localStorage.setItem("isSocialUser", String(this.authorized));
            this.router.navigateByUrl("dashboard/home");
          } else if (res.message == "Login failed. Incorrect email ID.") {
            this.toasterService.showWarning(message, "");
          } else if (res.message == "Login failed. Incorrect password.") {
            this.toasterService.showWarning(message, "");
          } else if (res.message == "Account is not verified.") {
            this.toasterService.showError(message, "");
          } else {
            this.toasterService.showError(message, "");
            this.router.navigateByUrl("login");
          }
          this.showLoader = false;
        },
        (err) => {
          console.log(err.status, err.message);
          // console.log("oops", err);
          if (err.status == 502) {
            this.toasterService.showError("Server is down.", "");
          } else {
            this.toasterService.showWarning(err.error.message, "");
          }
        }
      );
    }
  }

  // social sign starts from here

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      console.log("facebook login works");
      // return;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
      console.log(socialPlatform + " sign in data : ", userData);
      // const socialUserData = String(userData)
      // Now sign-in with userData
      if (userData != null) {
        this.authorized = true;
        this.user = userData;
        console.log("this.user", this.user);
        console.log("scl email", this.user["email"]);
        this.socialUserData = {
          email: this.user["email"],
          name: this.user["name"],
          id: this.user["id"],
          idToken: this.user["idToken"],
          provider: this.user["provider"],
          image: this.user["image"],
          token: this.user["token"],
        };
        localStorage.setItem(
          "socialUserData",
          JSON.stringify(this.socialUserData)
        );
        localStorage.setItem("launch_apps", userData["launch_apps"]);
        localStorage.setItem("create_event", userData["create_event"]);
        localStorage.setItem("view_public_event", userData["view_public_event"]);
        localStorage.setItem("userRole", "User");
        localStorage.setItem("userEmail", userData["email"]);
        localStorage.setItem("userCompany", "test");
        localStorage.setItem("userName", userData["name"]);
        localStorage.setItem("isSocialUser", String(this.authorized));
        this.registerSocialUser();
      }
    });
  }

  public registerSocialUser() {
    // method that is used to store social user details in db

    const email = this.socialUserData["email"];
    const userName = this.socialUserData["name"];
    const imagePath = this.socialUserData["image"];
    const provider = this.socialUserData["provider"];
    const token = this.socialUserData["token"];

    this.loginService
      .registerSocialUser(email, userName, imagePath, provider, token)
      .subscribe((res) => {
        console.log("res", res);
        const msg = res.message;

        if (res.status == "success") {
          this.toasterService.showSuccess(msg, "");
          this.router.navigateByUrl("dashboard/home");
        } else if (res.status == "fail") {
          this.toasterService.showError(msg, "");
        }
      });
  }

  public signOut() {
    this.socialAuthService.signOut();
    this.authorized = false;
  }

  // social sign ends here

  // Show password Function
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  
}













