import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.prod";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private http: HttpService) {}

  //download link
  getDownload():Observable<any> {
    const url = `${environment.BASE_URL}/auth/downloadlink`;
    const formData = new FormData();
    return this.http.post(url, formData);
  }

  // Login
  getlogin(data): Observable<any> {
    // console.log(environment)
    const url = `${environment.BASE_URL}/auth/login`;
    const formData = new FormData();
    formData.append("email", (data.email).toLowerCase());
    formData.append("password", data.password);
    // console.log('form data',formData)
    return this.http.post(url, formData);
  }

  // User Registration
  registerUser(data: any): Observable<any> {
    const url = `${environment.BASE_URL}/auth/register`;
    const formData = new FormData();
    formData.append("email", data["email"].toLowerCase());
    formData.append("password", data["password"]);
    formData.append("Gender", data["gender"]);
    formData.append("UserName", data["userName"]);
    formData.append("Role", data["role"]);
    formData.append("companyName", data["companyName"]);
    return this.http.post(url, formData);
  }

  // forgot-password
  resetPassword(email: string): Observable<any> {
    const url = `${environment.BASE_URL}/auth/resetPassword`;
    const formData = new FormData();
    formData.append("email", email);
    return this.http.post(url, formData);
  }

  //update password
  newPassword(email: string, password: string): Observable<any> {
    const url = `${environment.BASE_URL}/auth/updatePassword`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return this.http.post(url, formData);
  }

  // change password
  changePassword(password, newPassword, email): Observable<any> {
    let url: string = `${environment.BASE_URL}/auth/changePassword`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("newpassword", newPassword);
    return this.http.post(url, formData);
  }
  registerSocialUser(
    email?: string,
    userName?: string,
    imagePath?: string,
    provider?: string,
    token?: string
  ) {
    let url: string = `${environment.BASE_URL}/auth/sociallogin`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("userName", userName);
    formData.append("imagePath", imagePath);
    formData.append("provider", provider);
    formData.append("token", token);

    // debugger
    return this.http.post(url, formData);
  }

  deviceLogout(email: string): Observable<any> {
    const formData = new FormData();
    formData.append("email", email);
    console.log("form data", formData);
    let url: string = `${environment.BASE_URL}/auth/device_logout`;
    return this.http.post(url, formData);
  }

  contactUs(data?: any): Observable<any> {
    const formData = new FormData();
    console.log("data", data);

    formData.append("email", data["email"]);
    formData.append("name", data["name"]);
    formData.append("subject", data["subject"]);
    formData.append("message", data["message"]);
    let url: string = `${environment.BASE_URL}/contact/addContact`;
    return this.http.post(url, formData);
  }
  bookDemo(data?: any): Observable<any> {
    const formData = new FormData();
    console.log("data", data);

    formData.append("email", data["email1"]);
    formData.append("firstName", data["firstname"]);
    formData.append("lastName", data["lastname"]);
    formData.append("phoneNumber", data["phonenumber"]);
    formData.append("message", data["message"]);
    // formData.append("demo_date", demoDate);
    formData.append("occUpdates", data["occUpdates"]);
    let url: string = `${environment.BASE_URL}/contact/bookDemo`;
    return this.http.post(url, formData);
  }
  addOculusId(oculusId?: any): Observable<any> {
    const formData = new FormData();
    formData.append("oculus_id", oculusId);
    const url = `${environment.BASE_URL}/oculus/addOculus`;
    return this.http.post(url, formData);
  }
}
