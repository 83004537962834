import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "src/app/providers/login.service";
import { environment } from "src/environments/environment.prod";
import { ToasterService } from "src/app/providers/toaster.service";
import { DatePipe } from "@angular/common";
import * as Mousetrap from 'src/assets/js/mousetrap.min.js';
import * as THREE from 'src/assets/js/three.min.js';
declare var $: any;
@Component({
  selector: "app-home-latest",
  templateUrl: "./home-latest.component.html",
  styleUrls: ["./home-latest.component.css"],
  providers: [DatePipe],
})
export class HomeLatestComponent implements OnInit {
  contactUsForm: FormGroup;
  bookDemoForm: FormGroup;
  oculusForm: FormGroup;
  windowsUrl = '';//environment.windows_build_url;
  questUrl = '';//environment.quest_build_url;
  private readonly email_pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  private readonly name_pattern = "^[a-zA-Z]+$"; // Only alphabets for username pattern
  phone_pattern = "^[6-9]{1}[0-9]{9}$";
  submitted = false;
  showLoader: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private toasterService: ToasterService,
    private datepipe: DatePipe
  ) {}

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  ngOnInit() {
    this.initForm();
    this.ccc();
    this.downloadlink();
    /*this.abcd();*/
  }
  downloadlink(){
    this.loginService.getDownload().subscribe(
      (res?: any) => {
        console.log("res", res);
        const msg = res.message;
        if (res.status == "success") {
          localStorage.setItem("windowsUrl", res.data.windowslink);
          localStorage.setItem("questUrl", res.data.questlink);
          this.windowsUrl=res.data.windowslink;
          this.questUrl=res.data.questlink;
        } else if (res.status == "fail") {
          this.windowsUrl='#';
          this.questUrl='#';
          localStorage.setItem("windowsUrl", '#');
          localStorage.setItem("questUrl", '#');
        }       
      },
      (err) => {
        console.log(err.status, err.message);
        // console.log("oops", err);
        if (err.status == 502) {
          this.toasterService.showError("Server is down.", "");
        } else {
          this.toasterService.showWarning(err.error.message, "");
        }
      }
    );
  }
  goToLogin() {
    this.router.navigateByUrl("/login");
  }
  goToSignUp() {
    this.router.navigateByUrl("/signup");
  }
  initForm() {
    this.contactUsForm = this.fb.group({
      name: ["", [Validators.required, Validators.pattern(this.name_pattern)]],
      email: [
        "",
        [Validators.required, Validators.pattern(this.email_pattern)],
      ],
      subject: ["", Validators.required],
      message: ["", Validators.required],
    });
    this.bookDemoForm = this.fb.group({
      email1: [
        "",
        [Validators.required, Validators.pattern(this.email_pattern)],
      ],
      firstname: [
        "",
        [Validators.required, Validators.pattern(this.name_pattern)],
      ],
      lastname: [
        "",
        [Validators.required, Validators.pattern(this.name_pattern)],
      ],
      phonenumber: [
        "",
        [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
      ],
      message: ["", Validators.required],
      // date: ["", Validators.required],
      // time: ["", Validators.required],
      tnc: [false, Validators.requiredTrue],
      occUpdates: [false],
    });
    this.oculusForm = this.fb.group({
      oculusId: [
        "",
        [Validators.required, Validators.pattern(this.email_pattern)],
      ],
    });
  }
  get f() {
    return this.bookDemoForm.controls;
  }
  get fc() {
    return this.contactUsForm.controls;
  }
  get fo() {
    return this.oculusForm.controls;
  }

  contactUs() {
    this.submitted = true;
    if (this.contactUsForm.valid) {
      this.showLoader = true;
      this.loginService.contactUs(this.contactUsForm.value).subscribe(
        (res?: any) => {
          console.log("res", res);
          const msg = res.message;

          if (res.status == "success") {
            this.toasterService.showSuccess(msg, "");
            this.contactUsForm.reset();
            this.submitted = false;
            this.showLoader = false;
          } else if (res.status == "fail") {
            this.toasterService.showError(msg, "");
          }
         
        },
        (err) => {
          console.log(err.status, err.message);
          // console.log("oops", err);
          if (err.status == 502) {
            this.toasterService.showError("Server is down.", "");
          } else {
            this.toasterService.showWarning(err.error.message, "");
          }
        }
      );
    } 
  }
  bookDemo() {
    this.submitted = true;
    console.log("form data", this.bookDemoForm);

    // console.log(
    //   "date",
    //   this.datepipe.transform(this.bookDemoForm.get("date").value, "yyyy-MM-dd")
    // );
    // // console.log('date',this.bookDemoForm.get('date').value);
    // const date = this.datepipe.transform(
    //   this.bookDemoForm.get("date").value,
    //   "yyyy-MM-dd"
    // );
    // const time = this.bookDemoForm.get("time").value;
    // const demoDate = date + " " + time + ":00";
    // console.log("date time", demoDate);
    if (this.bookDemoForm.valid) {
      this.showLoader = true;
      this.loginService.bookDemo(this.bookDemoForm.value).subscribe(
        (res?: any) => {
          console.log("res", res);
          const msg = res.message;
          if (res.status == "success") {
            this.toasterService.showSuccess(msg, "");
            $("#bookADemoModal").modal("hide");
            this.bookDemoForm.reset();
          } else if (res.status == "fail") {
            this.toasterService.showError(msg, "");
          }
          this.showLoader = false;
          this.submitted = false;
        },
        (err) => {
          console.log(err.status, err.message);
          // console.log("oops", err);
          if (err.status == 502) {
            this.toasterService.showError("Server is down.", "");
          } else {
            this.toasterService.showWarning(err.error.message, "");
          }
        }
      );
      // this.submitted = false;
      // this.showLoader = false;
    } else {
      // this.toasterService.showWarning("All fields are mandatory", "");
      return;
    }
  }
  oculusDownload() {
    this.submitted = true;
    if (this.oculusForm.valid) {
      this.showLoader = true;
      this.loginService
        .addOculusId(this.oculusForm.get("oculusId").value)
        .subscribe(
          (res?: any) => {
            console.log("res", res);
            const msg = res.message;
            if (res.status == "success") {
              this.toasterService.showSuccess(msg, "");
              // this.downloadQuestBuild();
              this.oculusForm.reset();
              $("#oculusModal").modal("hide");
            } else if (res.status == "fail") {
              this.toasterService.showError(msg, "");
            }
            this.showLoader = false;
            this.submitted = false;
          },
          (err) => {
            console.log(err.status, err.message);
            // console.log("oops", err);
            if (err.status == 502) {
              this.toasterService.showError("Server is down.", "");
            } else {
              this.toasterService.showWarning(err.error.message, "");
            }
          }
        );
    } else {
      // this.toasterService.showWarning("Please enter oculus ID", "");
      return;
    }
  }

  downloadWindowsBuild() {
    window.open(this.windowsUrl, "_blank").focus();
  }
  downloadQuestBuild() {
    window.open(this.questUrl, "_blank").focus();
  }

  modelFormReset() {
    this.submitted = false;
  }

  ccc(){
    $(".range").click(function(){		
      $(".divcls").hide();
      $(this).removeClass("fade-in-svg");
      var id=$(this).attr('id');
      if(id=="divmain" || id=="divc1" || id=="divc2" || id=="divc3"){
        $("#d"+id+" div.divcls").show();
        $(this).addClass("fade-in-svg");
			  $("#d"+id+" div.divcls").addClass("fade-in-image");
      }
      else{
        $("#d"+id).show();
        $(this).addClass("fade-in-svg");
			  $("#d"+id).addClass("fade-in-image");
      }
    });
  }

  abcd(){
    function e(){
      document.getElementById("panorama").innerHTML="<span id='fullScreenMode'><span id='fullscreenPan'><img src='../../../../assets/vimg/fullscreen.png' /></span></span>";
      R=new THREE.PerspectiveCamera(Y,G,1,1e3),k=new THREE.Scene;
      var e=new THREE.Mesh(new THREE.SphereGeometry(500,60,40),new THREE.MeshBasicMaterial({map:q}));
      e.scale.x=-1,k.add(e),y=new THREE.WebGLRenderer({antialias:!0}),y.setSize(C,F),B.appendChild(y.domElement),R.aspect=window.innerWidth/window.innerHeight,R.updateProjectionMatrix(),n(),y.setPixelRatio(window.devicePixelRatio),y.setSize(window.innerWidth,window.innerHeight),B.addEventListener("mousedown",i,!1),B.addEventListener("mousewheel",u,!1),B.addEventListener("DOMMouseScroll",u,!1),window.addEventListener("resize",t,!1)
    }
    function n(){
      R.projectionMatrix.makePerspective(Y,window.innerWidth/window.innerHeight,1,1100)
    }
    function t(){
      R.aspect=window.innerWidth/window.innerHeight,R.updateProjectionMatrix(),y.setSize(window.innerWidth,window.innerHeight)
    }
    function i(e){
      e.preventDefault(),T=e.clientX,L=e.clientY,P=j,b=X,B.addEventListener("mousemove",r,!1),B.addEventListener("mouseup",c,!1),B.addEventListener("touchstart",a,!1),B.addEventListener("touchmove",o,!1)
    }
    function o(e){
      e.preventDefault();var n=e.touches[0];j-=.2*(n.screenX-S),X-=.2*(n.screenY-W),S=n.screenX,W=n.screenY
    }
    function a(e){
      e.preventDefault();var n=e.touches[0];
      S=n.screenX,W=n.screenY
    }
    function r(e){
      j=(e.clientX-T)*-.175+P,X=(e.clientY-L)*-.175+b
    }
    function c(e){
      B.removeEventListener("mousemove",r,!1),B.removeEventListener("mouseup",c,!1)
    }
    function u(e){
      e.wheelDeltaY?Y-=.05*e.wheelDeltaY:e.wheelDelta?Y-=.05*e.wheelDelta:e.detail&&(Y+=1*e.detail),(45>Y||Y>90)&&(Y=45>Y?45:90),R.projectionMatrix.makePerspective(Y,window.innerWidth/window.innerHeight,1,1100)
    }
    function s(){
      requestAnimationFrame(s),m()
    }
    function d(){
      function e(){
        j+=.25,40==n?clearInterval(t):n++
      }
      var n=0,t=setInterval(function(){e()},10)
    }
    function l(){
      function e(){j-=.25,40==n?clearInterval(t):n++}var n=0,t=setInterval(function(){e()},10)
    }
    function f(){
      function e(){X+=.25,40==n?clearInterval(t):n++
    }
    var n=0,t=setInterval(function(){e()},10)}
    function w(){
      function e(){
        X-=.25,40==n?clearInterval(t):n++
      }
      var n=0,t=setInterval(function(){e()},10)
    }
    function m(){
      _===!1&&(j+=.05),X=Math.max(-85,Math.min(85,X)),N=THREE.Math.degToRad(90-X),A=THREE.Math.degToRad(j),R.position.x=100*Math.sin(N)*Math.cos(A),R.position.y=100*Math.cos(N),R.position.z=100*Math.sin(N)*Math.sin(A);var e="x: "+R.position.x;e+="<br/>y: "+R.position.y,e+="<br/>z: "+R.position.z,e+="<br/>fov: "+Y,R.lookAt(k.position),y.render(k,R)
    }
    function p(){
      autoScrol===!1&&(_=!0),$("#elipse_key").click(function(){_=_===!1?!0:!1,m()})
    }
    function v(){
      $("#right_key").on("mousedown touchstart",d),$("#left_key").on("mousedown touchstart",l),$("#down_key").on("mousedown touchstart",f),$("#up_key").on("mousedown touchstart",w)
    }
    function h(){
      $("#zoom_in").on("mousedown touchstart",E),$("#zoom_out").on("mousedown touchstart",M)
    }
    function E(){
      function e(){
        (45>Y||Y>90)&&(Y=45>Y?45:90),Y-=.25,R.projectionMatrix.makePerspective(Y,window.innerWidth/window.innerHeight,1,1100),40==n?clearInterval(t):n++
      }
      var n=0,t=setInterval(function(){e()},10)
    }
    function M(){
      function e(){
        (45>Y||Y>90)&&(Y=45>Y?45:90),Y+=.25,R.projectionMatrix.makePerspective(Y,window.innerWidth/window.innerHeight,1,1100),40==n?clearInterval(t):n++
      }
      var n=0,t=setInterval(function(){e()},10)
    }
    function g(){
      $("#fullscreenPan").bind("touchstart click",function(){$("#panorama").toggleFullScreen(),D=!0}),H()
    }
    function H(){
      $("#panorama").fullScreen(!1),D=!1
    }
    function I(){
      $("#pButton").click(function(){
        //music.paused?(music.play(),pButton.className="",pButton.className="fa fa-volume-up"):(music.pause(),pButton.className="",pButton.className="fa fa-volume-off")
      })
    }
    function x(){
      $("#panorama").css("height",z+"px")
    }
    function AB() {
      $(".newItem").click(function () {
        var iimg=$(this).attr('setid');
        alert("HI");
        $("#panoramaImage").attr("src",iimg);
        //document.getElementById("panoramaImage").src = iimg;
        var R,k,y,T,L,P,b,z,S,autoScrol,W,B=document.getElementById("panorama"),D=!1,Y=70,_=!1,j=0,X=0,N=0,A=0,C=1920,F=1080,G=C/F,U=iimg,q=THREE.ImageUtils.loadTexture(iimg,new THREE.UVMapping,function(){e(),s(),g()});Mousetrap.bind("right",function(){d()}),Mousetrap.bind("left",function(){l()}),Mousetrap.bind("down",function(){f()}),Mousetrap.bind("up",function(){w()}),p(),v(),h(),I(),x(),H()
      });
    }
    //document.getElementById("panoramaImage").src
    var R,k,y,T,L,P,b,z,S,autoScrol,W,B=document.getElementById("panorama"),D=!1,Y=70,_=!1,j=0,X=0,N=0,A=0,C=1920,F=1080,G=C/F,U=$("#panoramaImage").attr("src"),q=THREE.ImageUtils.loadTexture(U,new THREE.UVMapping,function(){e(),s()});Mousetrap.bind("right",function(){d()}),Mousetrap.bind("left",function(){l()}),Mousetrap.bind("down",function(){f()}),Mousetrap.bind("up",function(){w()}),p(),v(),g(),h(),I(),AB(),x(),H()
  }
}
